const burgerToggle = (burger) => {
  const mobileMenu = document.querySelector('.j-mobile-menu');
  const body = document.querySelector('body');

  burger.addEventListener('click', function (e) {
    e.preventDefault();
    this.classList.toggle('burger_active');
    mobileMenu.classList.toggle('mobile-menu_active');
    body.classList.toggle('scroll-locked');
  });
};

export default burgerToggle;