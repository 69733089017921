const scrollToPosition = (element, options = {}) => {
  let elementToScroll;
  if (typeof element === 'string') {
    elementToScroll = document.getElementById(element);
  } else if (typeof element === 'object') {
    elementToScroll = element;
  }
  if (!elementToScroll) {
    return;
  }
  elementToScroll.scrollIntoView({
    block: 'center',
    behavior: 'smooth',
    ...options,
  });
};

export default scrollToPosition;
