const headerScroll = (element) => {
  const body = document.querySelector('body');

  window.addEventListener('scroll', () => {
    const scrollerFromTop = window.pageYOffset;

    if (scrollerFromTop > 100) {
      body.classList.add('padding-top');
      element.classList.add('header_scrolled');
    } else {
      body.classList.remove('padding-top');
      element.classList.remove('header_scrolled');
    }

    if (scrollerFromTop > 300) {
      element.classList.add('header_show');
    } else {
      element.classList.remove('header_show');
    }
  });
};

export default headerScroll;
