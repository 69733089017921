const popupOpen = (link) => {
  const body = document.querySelector('body');
  const popupId = link.getAttribute('href');
  const currentPopup = document.querySelector(popupId);

  if (!currentPopup) {
    return;
  }

  link.addEventListener('click', (e) => {
    e.preventDefault();

    body.classList.add('scroll-locked');
    currentPopup.classList.add('popup_active');
  });
};

export default popupOpen;
