const popupClose = (button) => {
  const body = document.querySelector('body');
  const mobileMenu = document.querySelector('.j-mobile-menu');

  button.addEventListener('click', (e) => {
    const isOverlay = e.target.classList.contains('j-overlay');
    const isButtonClose = button.classList.contains('j-popup__close');
    const isMobileMenuOpened = mobileMenu && mobileMenu.classList.contains('mobile-menu_active');
    const popups = document.querySelectorAll('.popup_active');

    if (isOverlay || isButtonClose) {
      popups.forEach((item) => item.classList.remove('popup_active'));
      if (!isMobileMenuOpened) {
        body.classList.remove('scroll-locked');
      }
    }
  });
};

export default popupClose;
