import scrollToPosition from './scroll-to-position';

const menuClick = (element) => {
  const blockId = element.getAttribute('href');
  const slicedBlockId = blockId && blockId.slice(1);
  const currentSection = slicedBlockId && document.getElementById(slicedBlockId);
  const mobileMenu = document.querySelector('.j-mobile-menu');
  const burger = document.querySelector('.j-burger');
  const body = document.querySelector('body');

  if (!currentSection) {
    return;
  }

  element.addEventListener('click', (e) => {
    e.preventDefault();

    const isMobileMenuOpened = mobileMenu.classList.contains('mobile-menu_active');
    if (isMobileMenuOpened) {
      body.classList.remove('scroll-locked');
      mobileMenu.classList.remove('mobile-menu_active');
      burger.classList.remove('burger_active');
    }
    scrollToPosition(currentSection, { block: 'start' });
  });
};

export default menuClick;
