/**
 * getDevices
 * @returns {null|{iPhone: boolean, iPad: boolean, Android: boolean}}
 */

const getDevices = (ua) => ({
  iPad: ua ? /iPad/.test(ua) : false,
  iPhone: ua ? /iPhone/.test(ua) : false,
  Android: ua ? /Android/.test(ua) : false,
});

export default getDevices;