import * as EmailValidator from 'email-validator';
import parsePhoneNumber from 'libphonenumber-js'
import axios from 'axios';

const formSubmitHandler = (form) => {
  const popupThanks = document.querySelector('#thanks');
  const body = document.querySelector('body');
  const button = form.querySelector('.j-form__button');
  const EMAIL_OR_PHONE_ID = 'entry.1330885341';
  const MESSAGE_ID = 'entry.5415896';
  const CORS_PROXY = 'https://api.allorigins.win/get?url=';

  form.addEventListener('submit', function (e) {
    e.preventDefault();

    const currentPopup = document.querySelector('.popup_active');
    const emailOrPhoneField = e.target[EMAIL_OR_PHONE_ID];
    const messageField = e.target[MESSAGE_ID];
    const emailOrPhoneValue = emailOrPhoneField ? emailOrPhoneField.value : '';
    const messageValue = messageField ? messageField.value : '';
    const isEmail = EmailValidator.validate(emailOrPhoneValue);
    const phoneNumber = parsePhoneNumber(emailOrPhoneValue)
    const isPhoneNumberValid = !!phoneNumber && phoneNumber.isValid();
    const isFormValid = isEmail || isPhoneNumberValid;
    const actionUrl = this.getAttribute('action');
    const emailOrPhoneData = `${EMAIL_OR_PHONE_ID}=${emailOrPhoneValue}`;
    const messageData = `${MESSAGE_ID}=${messageValue}`;
    const actionUrlWithParams = `${actionUrl}?${emailOrPhoneData}&${messageData}&submit=Submit`;

    if (!isFormValid) {
      form.classList.add('form_error');
      return;
    }

    form.classList.remove('form_error');
    button.setAttribute('disabled', 'disabled');

    axios({
      method: 'get',
      url: CORS_PROXY + encodeURIComponent(actionUrlWithParams),
    })
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          button.removeAttribute('disabled');
          popupThanks.classList.add('popup_active');
          body.classList.add('scroll-locked');
          e.target[EMAIL_OR_PHONE_ID].value = '';
          if (messageField) {
            e.target[MESSAGE_ID].value = '';
          }

          setTimeout(() => {
            if (currentPopup) {
              currentPopup.classList.remove('popup_active');
            }
            popupThanks.classList.remove('popup_active');
            body.classList.remove('scroll-locked');
          }, 5000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  });
};

export default formSubmitHandler;
