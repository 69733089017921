import simpleParallax from "simple-parallax-js";

const PARALLAX_OPTIONS = {
  scale: 1.3,
  overflow: true,
  customWrapper: '.advantage__picture',
};

const makeImagesParallax = (images) => {
  let imagesParallax;
  if (window.innerWidth >= 769) {
    imagesParallax = new simpleParallax(images, PARALLAX_OPTIONS);
  }

  window.addEventListener('resize', () => {
    if (!imagesParallax) {
      return;
    }
    if (window.innerWidth < 769) {
      imagesParallax.destroy();
    }
  })
};

export default makeImagesParallax;
