/**
 * setAttributeHidden
 * @param item {object}
 * @param devices {object}
 * @param attribute {string}
 */
const setAttributeHidden = (item, devices, attribute) => {
  const itemDevice = item.getAttribute(attribute);
  const isDesktop = !devices.android && !devices.ios;

  if (!itemDevice || devices[itemDevice] || isDesktop) {
    return;
  }

  item.setAttribute('hidden', 'hidden');
};

export default setAttributeHidden;
